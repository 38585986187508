import React, {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {post} from "../../services/BaseService";
import {authUrls} from "../../util/api_constant";
import {Helmet} from "react-helmet";
import {graphql} from "gatsby";
import {useTranslation} from "gatsby-plugin-react-i18next";


const ForgotPassword = ({}: any) => {
    const [eye, setEye] = useState(false);
    const [current, setCurrent] = useState<number>(1);
    const [errorSubmitted, setErrorSubmitted] = useState("");
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    const { t, i18n } = useTranslation()
    const {handleSubmit, control} = useForm();

    const showPwd = () => setEye(!eye);

    function onSubmitForgotForm(data: any) {
        setLoading(true);
        post(`${authUrls.forgot}`+'?username='+data.username,{}).then((res) => {
            setToken(res.token);
            setCurrent(2);
        }).catch(()=>{
            setErrorSubmitted("une erreur c'est produite durant l'envoi du code !")
        }).finally(()=>{setLoading(false)})
    }

    function onSubmitResetForm(data: any) {
        setLoading(true);
        let dataToSend = {
            token : token,
            password : data.password,
            confirm : data.confirm
        }
        post(`${authUrls.changePwd}`+'?code='+data.code, dataToSend).then(() => {
            setCurrent(3);
        }).catch(()=>{
            setErrorSubmitted("une erreur c'est produite !")
        }).finally(()=>{setLoading(false)})
    }

    const generateContent = (stepper: number) => {
        switch (stepper) {
            case 1:
                return <ForgotForm/>
            case 2:
                return <ResetForm/>
            case 3:
                return <FinishMsg/>
            default:
                return <ForgotForm/>
        }
    }

    const ForgotForm = () => {
        return (
            <form onSubmit={handleSubmit(onSubmitForgotForm)}>
                <div className="mb-3">
                    <div className="input-group-merge">
                        <div className="input-group-prepend input-group-text" id="inputGroupMergeEmailAddOn">
                            <i className="bi-envelope-open" />
                        </div>
                        <Controller
                            name="username"
                            control={control}
                            defaultValue=""
                            render={({field, formState: {errors}}) =>
                                <>
                                    <input type="text"
                                           className="form-control"
                                           id="inputGroupMergeEmail"
                                           placeholder="email@example.com"
                                           aria-label="email@example.com"
                                           aria-describedby="inputGroupMergeEmailAddOn"
                                           {...field}
                                    />
                                    <div
                                        className="error-msg">  {errors.username && "Email obligatoire"}</div>
                                </>
                            }
                        />
                    </div>
                </div>
                <div style={{marginTop:30}}>
                    <button type="submit" className="btn btn-block btn-primary" disabled={loading}>
                        {loading ? "En cours ..." : "M'envoyer le code"}
                    </button>
                </div>
            </form>
        )
    }

    const ResetForm = () => {
        return (
            <form onSubmit={handleSubmit(onSubmitResetForm)}>
                <div className="mb-3">
                    <div className="input-group-merge">
                        <div className="input-group-prepend input-group-text" id="inputGroupMergePasswordAddOn">
                            <i className="bi-fullscreen"/>
                        </div>
                        <Controller
                            name="code"
                            control={control}
                            defaultValue=""
                            render={({field}) =>
                                <>
                                    <input type="text"
                                           className="form-control"
                                           id="inputGroupMergeCode"
                                           placeholder="code reçut par mail"
                                           aria-label=""
                                           aria-describedby="inputGroupMergeCodeAddOn"
                                           {...field}
                                    />
                                </>
                            }
                        />
                    </div>
                </div>
                <div className="mb-3">
                    <div className="input-group-merge">
                        <div className="input-group-prepend input-group-text" id="inputGroupMergePasswordAddOn">
                            <i className="bi-key-fill"/>
                        </div>
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            render={({field}) =>
                                <>
                                    <input type={eye ? "text" : "password"}
                                           className="form-control"
                                           id="inputGroupMergePassword"
                                           placeholder="********"
                                           aria-label="********"
                                           aria-describedby="inputGroupMergePasswordAddOn"
                                           {...field}
                                    />
                                    <span id="changePassTarget" className="input-group-append input-group-text"
                                          onClick={showPwd}><i id="changePassIcon"
                                                               className={eye ? "bi-eye-slash" : "bi-eye"}/>
                                        </span>
                                </>
                            }
                        />
                    </div>
                </div>
                <div className="mb-3">
                    <div className="input-group-merge">
                        <div className="input-group-prepend input-group-text" id="inputGroupMergePasswordAddOn">
                            <i className="bi-key-fill"/>
                        </div>
                        <Controller
                            name="confirm"
                            control={control}
                            defaultValue=""
                            render={({field}) =>
                                <>
                                    <input type={eye ? "text" : "password"}
                                           className="form-control"
                                           id="inputGroupMergeConfirm"
                                           placeholder="********"
                                           aria-label="********"
                                           aria-describedby="inputGroupMergePasswordAddOn"
                                           {...field}
                                    />
                                    <span id="changePassTarget" className="input-group-append input-group-text"
                                          onClick={showPwd}><i id="changePassIcon"
                                                               className={eye ? "bi-eye-slash" : "bi-eye"}/>
                                        </span>
                                </>
                            }
                        />
                    </div>
                </div>
                <div>
                    <button type="submit" className="btn btn-primary btn-lg" disabled={loading}>
                        {loading ? "En cours ..." : "Mettre à jour"}
                    </button>
                </div>
            </form>
        )
    }

    const FinishMsg = () => {
        return (
            <div className="alert alert-success" role="alert">
                <h3 className="alert-heading">Réinitialisée!</h3>
                <p className="text-white">
                    mot de passe réinitialisée avec succès vous pouvez pour connecter avec votre nouveau mot de passe
                </p>
                <hr />
                <div style={{marginTop:30}}>
                    <button className="btn btn-block btn-primary" onClick={()=>{location.replace('/app/login')}}>
                        se connecter
                    </button>
                </div>
            </div>

        )
    }

    return (
        <>
            <Helmet>
                <title>OHADA | {t('forgotPass')}</title>
            </Helmet>
            <main id="content" role="main">
                <div className="d-lg-flex half">
                    <div className="bg order-1 order-md-2" style={{background: "linear-gradient(#ff64001c 25%, #ff6400b5 90%), linear-gradient(to right, #ff64001c 0%, #ef88453d 90%), " + "url(/assets/img/950x950/img4.jpg)", backgroundSize: 'cover'}}>
                    </div>
                    <div className="contents order-2 order-md-1">
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-7 login-content">
                                    <h3>Mot de passe oublié ?</h3> <br/><br/>
                                    {errorSubmitted !== "" && (<span className="error-submit-msg">{errorSubmitted}</span>)}
                                    {generateContent(current)}<br/>
                                    <span className="ml-auto text-muted text-center">
                                        <a className="link" href="/app/login">Connexion</a>&nbsp;&nbsp;ou&nbsp;&nbsp;
                                        <a className="link" href="/">Retourné à l'accueil</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default ForgotPassword;


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
